import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Chatbots con IA",
      description: "Implementa chatbots con inteligencia artificial para manejar un gran volumen de consultas de manera simultánea y sin demoras, guiando a los clientes a través de opciones predefinidas o respondiendo a preguntas abiertas de manera natural. Todo eso con opciones interactivas, como botones de respuesta rápida o menús desplegables.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Análisis de sentimientos",
      description: "Analiza contenido generado por los clientes en los canales de chats y teléfono. Comprende los sentimientos y emociones expresados por los usuarios en cada consulta para comprenderlos mejor y utiliza esta información para mejorar la calidad del servicio al cliente y desarrollar estrategias de satisfacción y fidelización.",
      image: imageTwoMobile,
      background: "#FADEDD"
    },
    {
      title: "Personalización",
      description: "Utiliza los datos de comportamiento, preferencias y compras pasadas para ofrecer experiencias personalizadas a los clientes como recomendaciones de productos ofertas especiales y comunicaciones personalizadas. Aumenta la relevancia de las interacciones con los clientes y mejora la satisfacción.",
      image: imageThreeMobile,
      background: "#EEEAFB"
    },
    {
      title: "Asistencia para agentes",
      description: "Proporciona asistencia en tiempo real a los agentes de atención, ofreciendo información relevante y sugerencias para resolver consultas de manera más eficiente. Además, la IA también puede ayudar a los agentes a priorizar casos, asignar recursos de manera más efectiva y mejorar la colaboración dentro del equipo de servicio al cliente.",
      image: imageFourMobile,
      background: "#FADEDD"
    },
  ]

  return (
    <section className="container-experience-atencion">
      <p className="container-experience-atencion-title">
        Utilizamos la <TitleUnderlineOne underline="inteligencia" /> <TitleUnderlineTwo underline="artificial" />  de ChatGPT para ofrecer experiencias de conversación únicas
      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-atencion-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-atencion-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-atencion-cardsOne-card-title">Chatbots con IA</p>
            <p className="container-experience-atencion-cardsOne-card-description">
              Implementa chatbots con inteligencia artificial para manejar un gran volumen de consultas de manera simultánea y sin demoras, guiando a los clientes a través de opciones predefinidas o respondiendo a preguntas abiertas de manera natural. Todo eso con opciones interactivas, como botones de respuesta rápida o menús desplegables.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-atencion-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-atencion-cardsOne-column">
          {/* columna */}
          <section className="container-experience-atencion-cardsOne-card" style={{ background: "#FADEDD", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-atencion-cardsOne-card-title">Análisis de sentimientos</p>
              <p className="container-experience-atencion-cardsOne-card-description">
                Analiza contenido generado por los clientes en los canales de chats y teléfono. Comprende los sentimientos y emociones expresados por los usuarios en cada consulta para comprenderlos mejor y utiliza esta información para mejorar la calidad del servicio al cliente y desarrollar estrategias de satisfacción y fidelización.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-atencion-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-atencion-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-atencion-cardsTwo-card" style={{ background: "#FADEDD", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-atencion-cardsTwo-card-title">Personalización</p>
            <p className="container-experience-atencion-cardsTwo-card-description">
              Utiliza los datos de comportamiento, preferencias y compras pasadas para ofrecer experiencias personalizadas a los clientes como recomendaciones de productos ofertas especiales y comunicaciones personalizadas. Aumenta la relevancia de las interacciones con los clientes y mejora la satisfacción.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-atencion-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-atencion-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-atencion-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-atencion-cardsTwo-card-title">Asistencia para agentes</p>
              <p className="container-experience-atencion-cardsTwo-card-description">
                Proporciona asistencia en tiempo real a los agentes de atención, ofreciendo información relevante y sugerencias para resolver consultas de manera más eficiente. Además, la IA también puede ayudar a los agentes a priorizar casos, asignar recursos de manera más efectiva y mejorar la colaboración dentro del equipo de servicio al cliente.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-atencion-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-atencion-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-atencion-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-atencion-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-atencion-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-atencion-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
