import React from "react"

export const TitleUnderlineAtencionOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="291" height="10" viewBox="0 0 291 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M54.1014 10C48.0851 9.07169 4.03239 10.1081 0.830371 9.82831C-1.78378 9.45317 2.39386 2.84689 4.82039 1.87407C5.67092 1.52436 6.65904 1.47351 8.92297 1.63247C12.5127 1.93131 58.9295 1.33997 58.9295 1.33997C59.5424 1.54344 61.381 0.856744 61.7187 1.10472C62.7819 1.68332 138.017 0.894891 144.433 0.8631C148.323 0.844025 145.246 1.23824 149.824 1.07928C159.58 0.761364 180.844 1.06657 188.236 0.704142C194.152 0.405302 195.428 0.392594 201.107 0.63421C206.91 0.850392 237.767 0.538832 241.294 0.074676C242.157 -0.0524901 242.82 -0.00797814 242.82 0.138263C242.82 0.170054 264.572 0.278142 264.672 0.392591C264.834 0.538832 265.497 0.608769 266.11 0.507036C267.248 0.354437 290.4 -0.198737 290.763 0.227269C291.901 1.44806 288.712 6.64915 286.023 7.91446C283.271 9.26878 268.161 7.74915 256.016 8.56302L199.468 8.64568L187.348 9.15433C185.96 8.71561 180.619 9.82196 177.392 9.03353C176.028 8.72197 166.397 8.67745 164.271 9.00809C162.42 9.26242 113.151 8.62023 108.098 9.50403C99.0923 8.76647 62.5693 9.82197 54.1014 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineAtencionTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="79" height="10" viewBox="0 0 79 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6873 10C13.054 9.07169 1.0947 10.1081 0.225427 9.82831C-0.484255 9.45317 0.649879 2.84689 1.30863 1.87407C1.53953 1.52436 1.80778 1.47351 2.42239 1.63247C3.39693 1.93131 15.998 1.33997 15.998 1.33997C16.1644 1.54344 16.6636 0.856744 16.7553 1.10472C17.0439 1.68332 37.4685 0.894891 39.2104 0.8631C40.2665 0.844025 39.4311 1.23824 40.6739 1.07928C43.3225 0.761364 49.0951 1.06657 51.1019 0.704142C52.708 0.405302 53.0543 0.392594 54.5959 0.63421C56.1715 0.850392 64.5485 0.538832 65.506 0.074676C65.7403 -0.0524901 65.9203 -0.00797814 65.9203 0.138263C65.9203 0.170054 71.8253 0.278142 71.8524 0.392591C71.8966 0.538832 72.0765 0.608769 72.2429 0.507036C72.5519 0.354437 78.8372 -0.198737 78.9357 0.227269C79.2447 1.44806 78.3788 6.64915 77.6488 7.91446C76.9017 9.26878 72.7998 7.74915 69.5027 8.56302L54.1511 8.64568L50.8608 9.15433C50.4839 8.71561 49.0339 9.82196 48.1579 9.03353C47.7877 8.72197 45.1731 8.67745 44.5959 9.00809C44.0933 9.26242 30.718 8.62023 29.3462 9.50403C26.9013 8.76647 16.9862 9.82197 14.6873 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineAtencionThree = ({ underline }) => (
  <span>
    {underline}
    <svg width="179" height="10" viewBox="0 0 179 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.2789 10C29.5781 9.07169 2.4804 10.1081 0.510778 9.82831C-1.09724 9.45317 1.47251 2.84689 2.96512 1.87407C3.4883 1.52436 4.09611 1.47351 5.4887 1.63247C7.69684 1.93131 36.2487 1.33997 36.2487 1.33997C36.6257 1.54344 37.7567 0.856744 37.9645 1.10472C38.6184 1.68332 84.8969 0.894891 88.8439 0.8631C91.2367 0.844025 89.344 1.23824 92.1599 1.07928C98.1611 0.761364 111.241 1.06657 115.788 0.704142C119.427 0.405302 120.212 0.392594 123.705 0.63421C127.275 0.850392 146.255 0.538832 148.425 0.074676C148.956 -0.0524901 149.364 -0.00797814 149.364 0.138263C149.364 0.170054 162.743 0.278142 162.805 0.392591C162.905 0.538832 163.313 0.608769 163.69 0.507036C164.39 0.354437 178.631 -0.198737 178.854 0.227269C179.554 1.44806 177.592 6.64915 175.938 7.91446C174.246 9.26878 164.951 7.74915 157.481 8.56302L122.697 8.64568L115.241 9.15433C114.387 8.71561 111.102 9.82196 109.117 9.03353C108.279 8.72197 102.354 8.67745 101.046 9.00809C99.9077 9.26242 69.6016 8.62023 66.4933 9.50403C60.9537 8.76647 38.4876 9.82197 33.2789 10Z" fill="#FFBA00" />
    </svg>
  </span>
)