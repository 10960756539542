/* eslint-disable no-undef */
import React from "react"
import TemplatePageAtencion from "@components/pageCasosdeUso/pageAtencion/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Plataforma de atención al cliente con IA para automatizar tu empresa [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/atencion-al-cliente/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Brinda experiencias de atención únicas que promuevan la fidelización con la ayuda de la inteligencia artificial."
      />

      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Plataforma de atención al cliente con IA para automatizar tu empresa"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/atencion-al-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Brinda experiencias de atención únicas que promuevan la fidelización con la ayuda de la inteligencia artificial."
      />
    </Helmet>
    <TemplatePageAtencion location={location} />
  </div>
)

export default IndexPage
